<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { pickBy } from "lodash";
import Loader from "../components/Loader.vue";
import Map from "../components/Map.vue";
import { useTrailStore } from "../trailStore";

const trailStore = useTrailStore();

const props = defineProps({
	slug: String,
});

const trail = ref(null);

const isLoadingImages = ref(false);

onMounted(async () => {
	trail.value = trailStore.getTrailBySlug(props.slug) ?? null;

	if (trail.value?.images.length) {
		isLoadingImages.value = true;

		await loadImages(trail.value.images).then((images) => {
			isLoadingImages.value = false;
		});
	}
});

onUnmounted(() => {
	trail.value = null;
	isLoadingImages.value = false;
});

async function loadImages(imageUrlArray) {
	const promiseArray = [];
	const imageArray = [];

	for (let imageUrl of imageUrlArray) {
		promiseArray.push(
			new Promise((resolve) => {
				const img = new Image();

				img.onload = function () {
					resolve();
				};

				img.src = imageUrl;
				imageArray.push(img);
			})
		);
	}

	await Promise.all(promiseArray);
	return imageArray;
}
</script>

<template>
	<div class="trail-page">
		<div class="trail-page__back">
			<router-link
				:to="{ name: 'home', query: pickBy(trailStore.filters) }"
				class="trail-page__back-link"
				>← Go back to Trails</router-link
			>
		</div>

		<template v-if="trail">
			<Transition mode="out-in" name="fade" appear>
				<div v-if="!isLoadingImages" class="trail-page__container">
					<div class="trail-page__header">
						<h2 v-text="trail.name" class="trail-page__title"></h2>
						<!-- <h3 v-text="trail.region.name" class="trail-page__region"></h3> -->
					</div>

					<!-- <div class="trail-page__icons">
					<div
						v-for="activity in trail.activities"
						class="trail-page__icon-container"
					>
						<img :src="activity.icon" alt="" class="trail-page__icon" />
					</div>
				</div> -->

					<div class="trail-page__content">
						<div class="trail-page__main">
							<div v-if="trail.images[0]" class="trail-page__image-container">
								<img :src="trail.images[0]" alt="" class="trail-page__image" />
							</div>
						</div>

						<div class="trail-page__side">
							<div v-if="trail.images[1]" class="trail-page__image-container">
								<img :src="trail.images[1]" alt="" class="trail-page__image" />
							</div>

							<div class="trail-page__map-container">
								<Map class="trail-page__map" height="300" />
							</div>
						</div>
					</div>
				</div>
				<div v-else class="trail-page__loading">
					<div class="trail-page__loading-loader">
						<Loader />
					</div>
					<h4>Loading...</h4>
				</div>
			</Transition>
		</template>
	</div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
