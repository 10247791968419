<script setup>
import Loader from "./Loader.vue";
import { useTrailStore } from "../trailStore";

const trailStore = useTrailStore();
</script>

<template>
	<div>
		<Transition mode="out-in" name="fade" appear>
			<template v-if="!trailStore.isSearching">
				<div
					v-if="trailStore.filteredTrails.length"
					class="trail-finder__trails"
				>
					<div
						v-for="trail in trailStore.filteredTrails"
						class="trail-finder__trail"
					>
						<h4 v-text="trail.name" class="trail-finder__trail-title"></h4>

						<p
							v-text="trail.region.name"
							class="trail-finder__trail-region"
						></p>

						<div class="trail-finder__trail-icons">
							<div
								v-for="activity in trail.activities"
								class="trail-finder__trail-icon-container"
							>
								<img
									:src="activity.icon"
									alt=""
									class="trail-finder__trail-icon"
								/>
							</div>
						</div>

						<div class="trail-finder__trail-link">
							<router-link :to="{ name: 'trail', params: { slug: trail.slug } }"
								>Learn More <span></span
							></router-link>
						</div>
					</div>
				</div>

				<div v-else class="trail-finder__no-results">
					<h4>No trails found!</h4>
				</div>
			</template>

			<div v-else class="trail-finder__searching">
				<div class="trail-finder__searching-loader">
					<Loader />
				</div>
				<h4>Searching...</h4>
			</div>
		</Transition>
	</div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
