<script setup>
const loaderGif = new URL(
	`./assets/icons/loader.gif`,
	tooele_trails_map_object.url
).href;
</script>

<template>
	<img :src="loaderGif" alt="" />
</template>
